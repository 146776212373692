import React, {useEffect} from 'react';
import {NextPage} from 'next';
import firebase from 'firebase/compat/app';
import {BrightPageContext} from 'redux/store';
// Components
import Toast from 'components/shared/Toast';
// Actions
import {checkAuth, refreshAuth} from 'library/auth';
import router from 'next/router';
import {useDispatch} from 'react-redux';

/* eslint-disable  @typescript-eslint/no-explicit-any */
export const withLayout = (WrappedComponent: any, redirect = false) => {
  const Wrapper: NextPage = (props: {
    children?: React.ReactNode;
    currentUser?: firebase.User;
    continueUrl?: string;
  }) => {
    const dispatch = useDispatch();
    useEffect(() => {
      refreshAuth(dispatch, props.currentUser, props.continueUrl);
    }, []);
    return (
      <>
        <WrappedComponent {...props} />
        <Toast />
      </>
    );
  };

  Wrapper.getInitialProps = async (ctx: BrightPageContext) => {
    const continueUrl: string = ctx.query.continueUrl as string;
    const currentUser = await checkAuth(ctx.store.dispatch, ctx);
    if (redirect && currentUser) {
      const isServer = typeof window === 'undefined';
      if (isServer) {
        ctx?.res?.writeHead(302, {Location: '/home'});
        ctx?.res?.end();
      } else {
        router.push('/home');
      }
    }
    const componentProps =
      WrappedComponent.getInitialProps &&
      (await WrappedComponent.getInitialProps(ctx));

    return {...componentProps, currentUser, continueUrl};
  };

  return Wrapper;
};
